module.exports = {
  siteTitle: 'Avnish Choudhary',
  siteDescription:
    'Avnish Choudhary is a passionate Full Stack Developer having 6+ years of experience in building scalable applications.',
  siteKeywords:
    'Avnish Choudhary, Avnish, Choudhary, credit saison, software engineer, web developer, javascript, python, java, svvv, indore',
  siteUrl: 'https://av19h.dev',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-45666519-2',
  googleVerification: 'DCl7VAf9tcz6eD9gb67NfkNnJ1PKRNcg8qQiwpbx9Lk',
  name: 'Avnish Choudhary',
  location: 'Mumbai, India',
  email: 'av19h.dev@gmail.com',
  github: 'https://github.com/avnish-choudhary',
  twitterHandle: '@',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/avnish-choudhary',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/av19hdev/',
    },
    {
      name: 'Node',
      url: 'https://www.avnishchoudhary.com',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/av19h.dev',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/Geeky_Avnish',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#264653',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
